import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Social from "./social"
import {
  h1Header,
  h2Header,
  h3Header,
  h4Header,
  below,
} from "../styles/utilities"

const SplashWrapper = styled(motion.section)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .hero {
    ${h1Header};

    ${below.sm`
      ${h2Header};
    `}
  }

  .sub-hero {
    ${h3Header};

    ${below.sm`
      ${h4Header};
    `}
  }
`

export const Splash = () => {
  return (
    <SplashWrapper
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ type: "spring", damping: 15 }}
    >
      <h1 className="hero">JP Marra</h1>
      <h2 className="sub-hero">Engineering Leader</h2>
      <Social />
    </SplashWrapper>
  )
}
