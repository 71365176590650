import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import Twitter from "../assets/twitter.svg"
import Github from "../assets/github.svg"
import Email from "../assets/email.svg"
import Linkedin from "../assets/linkedin.svg"
import { White } from "../styles/utilities"

const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 20px;

  .social-link {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${White};
    border-radius: 50%;
    color: ${White};

    &__icon {
      width: 20px;
      * {
        fill: currentColor;
      }
    }
  }
`

const SocialLink = ({ children, link, label }) => (
  <motion.a
    aria-label={label}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    href={link}
    className="social-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </motion.a>
)

const Social = () => {
  return (
    <SocialButtons>
      <SocialLink link="https://twitter.com/JP_Marra" label="My Twitter">
        <Twitter className="social-link__icon" />
      </SocialLink>
      <SocialLink
        link="https://www.linkedin.com/in/jpmarra/"
        label="My Linkedin"
      >
        <Linkedin className="social-link__icon" />
      </SocialLink>
      <SocialLink link="https://github.com/jpmarra/" label="My Github">
        <Github className="social-link__icon" />
      </SocialLink>
      <SocialLink link="mailto:jp.marra@me.com" label="Email me">
        <Email className="social-link__icon" />
      </SocialLink>
    </SocialButtons>
  )
}

export default Social
